import { React, useEffect, useState } from 'react'
import Layout from '../Layout'
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase/Config.js";
import CurrencyFormat from 'react-currency-format';
import moment from 'moment'
import './Home.css'
import SaleProfit from '../helpers/graph/SaleProfit';
function Home() {

  const [vehicleDetails, setVehicleDetails] = useState();
  const [vehicleStockCount, setVehicleStockCount] = useState(0);
  const [stockAmt, setStockAmt] = useState(0)
  const [customerPurchaseAmt, setCustomerPurchaseAmt] = useState(0)
  const [saledVehicleCount, setSaledVehicleCount] = useState(0)
  const [saledVehicleAmt, setSaledVehicleAmt] = useState(0)
  const [bookingCount, setBookingCount] = useState(0)
  const [totalBookingAmt, setTotalBookingAmt] = useState(0)
  const [financeVehicles, setFinanceVehicles] = useState(0)
  const [commissionAmt, setCommissionAmt] = useState(0)
  const [startProfitDate, setProfitStartDate] = useState('')
  const [endProfitDate, setProfitEndDate] = useState(moment(new Date()).utc().format('YYYY-MM-DD'))
  const [monthlyProfit, setMonthlyProfit] = useState('')
  const [profitAmt, setProfitAmt] = useState(0);
  const [expenseEndDate, setExpenseEndDate] = useState('');
  const [expenseStartDate, setExpenseStartDate] = useState('');
  const [expenseDetails, setExpenseDetails] = useState([])
  const [sortExpenseAmt, setSortExpenseAmt] = useState('0');
  const [soldStartDate, setSoldStartDate] = useState();
  const [soldEndDate, setSoldEndDate] = useState();
  const [alllVehicle, setAllVehicle] = useState();
// console.log(typeof(alllVehicle));
  useEffect(() => {
    getDocs(collection(db, "purchaseDetails")).then((result) => {
      setVehicleDetails(result.docs);
      let arr = []
      for (let item of result.docs) {
        arr.push(item.data())
      }
      setVehicleDetails([...arr])
      let stockVehicle = []
      stockVehicle = arr.filter(item => {
        return item.sellDetails.status == false && item.bookingStatus == false
      })
      setVehicleStockCount(stockVehicle.length)

      let expPur = 0
      for (let item of stockVehicle) {
        item.expenseOnPurchase.map((expItem) => {
          expPur += Number(expItem.amount)
        })
        expPur += Number(item.purchasedAmt)
      }
      setStockAmt(expPur)
      getTotalVehicelSaled(arr)
      totalVehicleBooking(arr)
      financeCommision(arr)
      setAllVehicle([...arr])
      let SaledVehcles = []
      SaledVehcles = arr.filter(item => {
        return item.sellDetails.status == true
      })
      setMonthlyProfit([...SaledVehcles]);
      getDocs(collection(db, "expenseTrack")).then((result) => {
        let arr1 = []
        for (let item of result.docs) {
          arr1.push(item.data())
        }
        setExpenseDetails([...arr1])
      })
    });

  }, [])
  

  useEffect(() => {
    if(soldStartDate && soldEndDate){
      let soldVehicelSort = alllVehicle.filter((item) => {
        return new Date(item.sellDetails.dateOfSale) > new Date(soldStartDate) && new Date(item.sellDetails.dateOfSale) < new Date(soldEndDate)
      })
      // console.log(soldVehicelSort);
      let purchasedAmount = 0
      for(let item of soldVehicelSort){
        purchasedAmount += Number(item.sellDetails.CustomerPurchasedAmt)
      }
      // console.log(purchasedAmount);
      setSaledVehicleCount(soldVehicelSort.length)
      setCustomerPurchaseAmt(purchasedAmount)
    }
  

    if (startProfitDate && endProfitDate) {
      let sortData = monthlyProfit.filter((item) => {
        return new Date(item.sellDetails.dateOfSale) > new Date(startProfitDate) && new Date(item.sellDetails.dateOfSale) < new Date(endProfitDate)
      })
      let customerPurchaseAmt = 0
      let exPur = 0

      for (let item of sortData) {
        customerPurchaseAmt += Number(item.sellDetails.CustomerPurchasedAmt)
        item.expenseOnPurchase.map((expItem) => {
          exPur += Number(expItem.amount)
        })
        exPur += Number(item.purchasedAmt)
      }
      let profit = customerPurchaseAmt - exPur;
      setProfitAmt(profit)
    }
    else if (expenseStartDate && expenseEndDate) {
      let sortExpense = [];
       sortExpense = expenseDetails?.filter((item) => {
        return new Date(item.date) > new Date(expenseStartDate) && new Date(item.date) < new Date(expenseEndDate)
      })
      let expenseAmount = 0;
      sortExpense.map((item)=>{
        expenseAmount += Number(item.amount)
      })
      setSortExpenseAmt(expenseAmount)
    }
  }, [startProfitDate, endProfitDate, expenseStartDate, expenseEndDate,soldStartDate,soldEndDate])

  function getTotalVehicelSaled(arr) {
    let saledVehicle = []
    saledVehicle = arr.filter(item => {
      return item.sellDetails.status == true
    })

    let expPur = 0
    for (let item of saledVehicle) {
      expPur += Number(item.sellDetails.CustomerPurchasedAmt)
    }
    setCustomerPurchaseAmt(expPur)
    setSaledVehicleCount(saledVehicle.length)
  }


  const totalVehicleBooking = (arr) => {
    let bookedVehicles = []
    bookedVehicles = arr.filter(item => {
      return item.bookingStatus == true
    })
    let bookingAmt = 0
    for (let item of bookedVehicles) {
      bookingAmt += Number(item.bookingDetails.bookingAmt)
    }
    setBookingCount(bookedVehicles.length);
    setTotalBookingAmt(bookingAmt)
  }

  const financeCommision = (arr) => {
    let financeCommision = []
    financeCommision = arr.filter((item) => {
      return item.sellDetails?.paymentMethode?.type == "finance"
    })
    let commissionAmt = 0
    for (let item of financeCommision) {
      commissionAmt += Number(item.sellDetails.paymentMethode.commission_amount)
    }
    setFinanceVehicles(financeCommision.length)
    setCommissionAmt(commissionAmt)

  }

  return (
    <Layout activePage={'home'}>
      <div className='content'>
        <div className="row mt-5">
          <div className="col-md-6 col-lg-4 col-xl-4 order-0 mb-4">
            <div className="card ">
              <div className="card-header d-flex align-items-center justify-content-between pb-0">
                <div className="card-title mb-0">
                  <h5 className="m-0 me-2">Total Vehicle Stock</h5>
                  <small className="text-muted">{`${vehicleStockCount} Total Stock`}</small>
                </div>

              </div>
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div className="tab-content p-0 mt-3">

                    {/* <p>amount</p> */}
                    <p style={{ fontSize: "9px", margin: '0px' }}>Amount included total cost of expense</p>
                    <CurrencyFormat className='fs-3' value={stockAmt} displayType={'text'} thousandSeparator={true} prefix={'₹'} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 order-1 mb-4 ">
            <div className="card ">
              <div className="card-header d-flex align-items-center justify-content-between pb-0">
                <div className="card-title mb-0">
                  <h5 className="m-0 me-2">Total Vehicle Saled</h5>
                  <label className='p-0'><input className='form-control' type="date" placeholder='start' value={soldStartDate} onChange={(e) => setSoldStartDate(e.target.value)} style={{ height: '25px', width: '135px' }} /></label>&nbsp;
                    <label className='p-0'><input className='form-control' type="date" placeholder='end' value={soldEndDate} onChange={(e) => setSoldEndDate(e.target.value)} style={{ height: '25px', width: '135px' }} /></label>

                  <small className="text-muted"><br/>{saledVehicleCount} Total Sales</small>
                </div>

              </div>
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div className="tab-content p-0 mt-3">
                    <p style={{ fontSize: "9px", margin: '0px' }}>Customer Purchased Amount</p>
                    <CurrencyFormat className='fs-3' value={customerPurchaseAmt} displayType={'text'} thousandSeparator={true} prefix={'₹'} />
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="col-md-6 col-lg-4 order-2 mb-4">
            <div className="card ">
              <div className="card-header d-flex align-items-center justify-content-between pb-0">
                <div className="card-title mb-0">
                  <h5 className="m-0 me-2">Total Vehicle Booking</h5>
                  <small className="text-muted">{bookingCount} Total Sales</small>
                </div>

              </div>
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div className="tab-content p-0 mt-3">
                    <p style={{ fontSize: "9px", margin: '0px' }}>Total booked amount</p>
                    <CurrencyFormat className='fs-3' value={totalBookingAmt} displayType={'text'} thousandSeparator={true} prefix={'₹'} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-4 col-lg-4 col-xl-4 order-0 mb-4">
            <div className="card ">
              <div className="card-header d-flex align-items-center justify-content-between pb-0">
                <div className="card-title mb-0 ">
                  <h5 className="m-0 me-2">Monthly Sale Profit</h5>
                  <div className=' justify-content-end mt-2 dateRangPicker '>
                    <label className='p-0'>start Date :<input className='form-control' type="date" placeholder='start' value={startProfitDate} onChange={(e) => setProfitStartDate(e.target.value)} style={{ height: '25px', width: '135px' }} /></label>&nbsp;
                    <label className='p-0'>end Date :<input className='form-control' type="date" placeholder='end' value={endProfitDate} onChange={(e) => setProfitEndDate(e.target.value)} style={{ height: '25px', width: '135px' }} /></label>
                  </div>
                </div>

              </div>
              <div className="card-body">
                <div className=" mb-3">
                  <div className="tab-content p-0 mt-3">
                    <p style={{ fontSize: "9px", margin: '0px' }}>Profit Amount</p>
                    <CurrencyFormat className='fs-3' value={profitAmt} displayType={'text'} thousandSeparator={true} prefix={'₹'} />
                  </div>
                  {/* <SaleProfit soldVehicles={monthlyProfit} /> */}

                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-lg-4 order-1 mb-4 ">
            <div className="card ">
              <div className="card-header d-flex align-items-center justify-content-between pb-0">
                <div className="card-title mb-0">
                  <h5 className="m-0 me-2">Finance Commmision </h5>
                  <small className="text-muted">{financeVehicles} Total Sales</small>
                </div>

              </div>
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div className="tab-content p-0 mt-3">
                    <p style={{ fontSize: "9px", margin: '0px' }}>Total finance commmision amount </p>
                    <CurrencyFormat className='fs-3' value={commissionAmt} displayType={'text'} thousandSeparator={true} prefix={'₹'} />
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="col-md-4 col-lg-4 order-2 mb-4">
            <div className="card ">
              <div className="card-header d-flex align-items-center justify-content-between pb-0">
                <div className="card-title mb-0">
                  <h5 className="m-0 me-2">Total Monthly Expense</h5>
                  <div className=' justify-content-end mt-2 dateRangPicker '>
                    <label className='p-0'>start Date :<input className='form-control' type="date" placeholder='start' value={expenseStartDate} onChange={(e) => setExpenseStartDate(e.target.value)} style={{ height: '25px', width: '135px' }} /></label>&nbsp;
                    <label className='p-0'>end Date :<input className='form-control' type="date" placeholder='end' value={expenseEndDate} onChange={(e) => setExpenseEndDate(e.target.value)} style={{ height: '25px', width: '135px' }} /></label>
                  </div>
                  {/* <small className="text-muted">42.82k Total Sales</small> */}
                </div>

              </div>
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div className="tab-content p-0 mt-3">

                    <p>amount</p>
                    <CurrencyFormat className='fs-3' value={sortExpenseAmt} displayType={'text'} thousandSeparator={true} prefix={'₹'} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



      </div>
    </Layout>
  )
}

export default Home