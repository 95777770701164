import React from "react";
import Layout from "../Layout";
import { Row, Col, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { doc, getDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { db } from "../../firebase/Config.js";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import CurrencyFormat from 'react-currency-format';


function VehicleDetails() {
  const navigate = useNavigate();

  const [detailsWithId, setDetailsWithId] = useState("");
  const [edit, setEdit] = useState(false);
  const [totalExpenseOnPurchase, setTotalExpenseOnPurchase] = useState("");
  const [vehicleName, setVehicleName] = useState("");
  const [dateOfPurchase, setDateOfPurchase] = useState("");
  const [source, setSource] = useState("");
  const [secondKey, setSecondKey] = useState("");
  const [purchasedAmt, setPurchasedAmt] = useState("");
  const [fuel, setFuel] = useState("");
  const [vehicleModel, setVehicleModel] = useState("");
  const [VehicleYear, setVehicleYear] = useState("");
  const [noc, setNoc] = useState("");
  const [note, setNote] = useState("");
  const [insuranceType, setInsuranceType] = useState('');
  const [insuranceDate, setInsuranceDate] = useState('');
  const [insuranceAmount, setInsuranceAmount] = useState('');
  const [insuranceCompanyName, setInsuranceCompanyName] = useState('');
  const [rcOwnerNo, setRcOwnerNo] = useState('')
  const [rcDateOfReg, setRcDateReg] = useState('')
  const [rcRegNo, setRcRegNo] = useState('')
  const [rcRegValidity, setRcRegValidity] = useState('')
  const [insurance, setInsurance] = useState({
  });
  const [rcDetails, setRcDetails] = useState({
  });
  const [customerName, setCustomerName] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [dateOfSale, setDateOfSale] = useState("");
  const [paymentType, setPaymentType] = useState('');
  const [finance_name, setFinance_name] = useState('');
  const [loan_amount, setLoan_amount] = useState('')
  const [disbursement_amount, setDisbursement_amount] = useState('')
  const [exicutive_name, setExicutive_name] = useState('')
  const [ref_no, setRef_no] = useState('')
  const [commission_amount, setCommission_amount] = useState('')
  const [customerPurchaseAmt, setCustomerPurchaseAmt] = useState('')
  const [bookingAmt, setBookingAmt] = useState("");
  const [dateOfBooking, setDateOfBooking] = useState("");
  const [formDisabled, setFormDisabled] = useState(true);
  const [expensePurpose, setExpensePurpose] = useState('');
  const [expenseAmount, setExpenseAmount] = useState('');
  const [expenseOnPurchase, setExpenseOnPurchase] = useState([
    { purpose: "", amount: "" },
  ]);
  // console.log(expenseOnPurchase);
  const disabled = "disabled";
  // console.log(expensePurpose);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...expenseOnPurchase];
    list[index][name] = value;
    setExpenseOnPurchase(list);
  };

  const handleRemove = (index) => {
    const list = [...expenseOnPurchase];
    list.splice(index, 1);
    setExpenseOnPurchase(list);
  };

  const handleAddClick = () => {
    setExpenseOnPurchase([...expenseOnPurchase, { purpose: "", amount: "" }]);
  };

  const urlParams = useParams();

  const multipleCancel = () => {
    cancelSale();
    cancelBooking();
  }

  async function cancelSale() {
    try {
      await updateDoc(doc(db, "purchaseDetails", urlParams.id), {
        sellDetails: {
          status: false,
        }
      });

    } catch (error) {
      alert(error);
    }

  }

  async function cancelBooking() {
    try {
      await updateDoc(doc(db, "purchaseDetails", urlParams.id), {
        bookingStatus: false,
      });
      alert("Successfully Canceled!");
    } catch (error) {
      alert(error);
    }
  }

  const editClick = () => {
    setFormDisabled(false)
  };

  function reloadClick() {
    setTimeout(function () {
      setFormDisabled(true)
    }, 1000);
  }

  const notify = () =>
    toast.success("Successfully Updated!", {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
// console.log(detailsWithId);
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await updateDoc(doc(db, "purchaseDetails", urlParams.id), {

        // bookingStatus:false,
        vehicleName: vehicleName ? vehicleName : detailsWithId.vehicleName ?? '',
        VehicleYear: VehicleYear ? VehicleYear : detailsWithId.VehicleYear ?? '',
        secondKey: secondKey ? secondKey : detailsWithId.secondKey ?? '',
        purchasedAmt: purchasedAmt ? purchasedAmt : detailsWithId.purchasedAmt ?? '',
        dateOfPurchase: dateOfPurchase ? dateOfPurchase : detailsWithId.dateOfPurchase ?? '',
        source: source ? source : detailsWithId.source ?? '',
        noc: noc ? noc : detailsWithId.noc ?? '',
        fuel: fuel ? fuel : detailsWithId.fuel ?? '',
        note: note ? note : detailsWithId.note ?? '',
        vehicleModel: vehicleModel ? vehicleModel : detailsWithId.vehicleModel ?? '',
        insurance: {
          type: insuranceType ? insuranceType : detailsWithId.insurance?.type ?? '',
          amount: insuranceAmount ? insuranceAmount : detailsWithId.insurance?.amount ?? '',
          date: insuranceDate ? insuranceDate : detailsWithId.insurance?.date ?? '',
          insuranceCompanyName: insuranceCompanyName ? insuranceCompanyName : detailsWithId.insurance?.insuranceCompanyName ?? ''
        },
        rcDetails: {
          owner_no: rcOwnerNo ? rcOwnerNo : detailsWithId.rcDetails?.owner_no ?? '',
          dateOf_reg: rcDateOfReg ? rcDateOfReg : detailsWithId.rcDetails?.dateOf_reg ?? '',
          reg_no: rcRegNo ? rcRegNo : detailsWithId.rcDetails?.reg_no ?? '',
          reg_validity: rcRegValidity ? rcRegValidity : detailsWithId.rcDetails?.reg_validity ?? ''
        },
        sellDetails: {
          status: detailsWithId.sellDetails?.status,
          customerName: customerName ? customerName : detailsWithId.sellDetails?.customerName ?? '',
          dateOfSale: dateOfSale ? dateOfSale : detailsWithId.sellDetails?.dateOfSale ?? '',
          customerPhone: customerPhone ? customerPhone : detailsWithId.sellDetails?.customerPhone ?? '',
          CustomerPurchasedAmt: customerPurchaseAmt ? customerPurchaseAmt : detailsWithId.sellDetails?.CustomerPurchasedAmt ?? '',
          // CustomerPurchasedAmt : purchasedAmt ? purchasedAmt : detailsWithId.sellDetails?.CustomerPurchasedAmt ?? '' ,
          paymentMethode: {
            type: paymentType ? paymentType : detailsWithId.sellDetails?.paymentMethode?.type?.paymentType ?? '',
            finance_name: finance_name ? finance_name : detailsWithId.sellDetails?.paymentMethode?.finance_name ?? '',
            loan_amount: loan_amount ? loan_amount : detailsWithId.sellDetails?.paymentMethode?.loan_amount ?? '',
            disbursement_amount: disbursement_amount ? disbursement_amount : detailsWithId.sellDetails?.paymentMethode?.disbursement_amount ?? '',
            exicutive_name: exicutive_name ? exicutive_name : detailsWithId.sellDetails?.paymentMethode?.exicutive_name ?? '',
            ref_no: ref_no ? ref_no : detailsWithId.sellDetails?.paymentMethode?.ref_no ?? '',
            commission_amount: commission_amount ? commission_amount : detailsWithId.sellDetails?.paymentMethode?.commission_amount ?? '',
          }
        },
        bookingDetails: {
          bookingAmt: bookingAmt ? bookingAmt : detailsWithId.bookingDetails?.bookingAmt ?? '',
          dateOfBooking: dateOfBooking ? dateOfBooking : detailsWithId.bookingDetails?.dateOfBooking ?? ''
        },
        expenseOnPurchase

      });
      notify();
      reloadClick();
    } catch (error) {
      alert(error);
    }
  };

  const deleteDetail = async () => {
    let userConfirmation = window.confirm('Are you sure you want to delete this document');

    if (userConfirmation) {
      await deleteDoc(doc(db, "purchaseDetails", urlParams.id));
      navigate('/stockList');
      notify();
    } else {
      return;
    }
  };



  useEffect(() => {
    const documentRef = doc(db, "purchaseDetails", urlParams.id);
    getDoc(documentRef)
      .then((doc) => {
        if (doc.exists()) {
          setDetailsWithId(doc.data());
          setExpenseOnPurchase(doc.data().expenseOnPurchase);
        } else {
          console.log("No such data");
        }
      })
      .catch((error) => {
        console.log("Error getting document", error);
      });
  }, [urlParams.id]);
  // console.log("s", detailsWithId.bookingStatus);
  // console.log(detailsWithId.expenseOnPurchase[0].purpose);

  return (
    <Layout>
      <div className="content p-5 ">
        <div className="p-5 border bg-light rounded-4">
          <h3>Vehcile Details</h3>
          <hr className="my-0 mb-4 mt-4" />
          <Form>
            <Row className="text-muted">
              <Col>
                <div>
                  <div>
                    <Row>
                      <Form.Label column lg={4} className="form-label mb-3">
                        Vehicle Name:
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          defaultValue={detailsWithId.vehicleName}
                          disabled={formDisabled}
                          onChange={(event) =>
                            setVehicleName(event.target.value)
                          }
                        />
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Form.Label column lg={4} className="form-label mb-3">
                        Vehicle model year:
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="month"
                          defaultValue={detailsWithId.VehicleYear}
                          disabled={formDisabled}
                          onChange={(event) =>
                            setVehicleYear(event.target.value)
                          }
                        />
                      </Col>
                    </Row>
                  </div>

                  <div>
                    <Row>
                      <Form.Label column lg={4} className="form-label mb-3">
                        Vehicle model:
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          defaultValue={detailsWithId.vehicleModel}
                          disabled={formDisabled}
                          onChange={(event) =>
                            setVehicleModel(event.target.value)
                          }
                        />
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Form.Label column lg={4} className="form-label mb-3">
                        second key:
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          defaultValue={detailsWithId.secondKey}
                          disabled={formDisabled}
                          onChange={(event) => setSecondKey(event.target.value)}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Form.Label column lg={4} className="form-label mb-3">
                        Purchased amount:
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          defaultValue={detailsWithId.purchasedAmt}
                          disabled={formDisabled}
                          onChange={(event) =>
                            setPurchasedAmt(event.target.value)
                          }
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col>
                <div>
                  <Row>
                    <Form.Label column lg={4} className="form-label mb-3">
                      Date of purchase:
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="date"
                        defaultValue={detailsWithId.dateOfPurchase}
                        disabled={formDisabled}
                        onChange={(event) =>
                          setDateOfPurchase(event.target.value)
                        }
                      />
                    </Col>
                  </Row>
                </div>
                <div>
                  <Row>
                    <Form.Label column lg={4} className="form-label mb-3">
                      Source:
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        defaultValue={detailsWithId.source}
                        disabled={formDisabled}
                        onChange={(event) => setSource(event.target.value)}
                      />
                    </Col>
                  </Row>
                </div>
                <div>
                  <Row>
                    <Form.Label column lg={4} className="form-label mb-3">
                      noc:
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        defaultValue={detailsWithId.noc}
                        disabled={formDisabled}
                        onChange={(event) => setNoc(event.target.value)}
                      />
                    </Col>
                  </Row>
                  <div>
                    <Row>
                      <Form.Label column lg={4} className="form-label mb-3">
                        Fuel Type:
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          defaultValue={detailsWithId.fuel}
                          disabled={formDisabled}
                          onChange={(event) => setFuel(event.target.value)}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
                <div>
                  <Row>
                    <Form.Label column lg={4} className="form-label mb-3">
                      note:
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        defaultValue={detailsWithId.note}
                        disabled={formDisabled}
                        onChange={(event) => setNote(event.target.value)}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <hr className="my-0 mb-4 mt-4" />
            <Row>
              <h4>Insurance</h4>
              <Col>
                <div>
                  <Row>
                    <Form.Label column lg={4} className="form-label mb-3">
                      Company name:
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        defaultValue={
                          detailsWithId.insurance?.insuranceCompanyName
                        }
                        disabled={formDisabled}
                        onChange={(event) => setInsuranceCompanyName(event.target.value)}
                      />
                    </Col>
                  </Row>
                </div>
                <div>
                  <Row>
                    <Form.Label column lg={4} className="form-label mb-3">
                      Validity end date:
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="date"
                        defaultValue={detailsWithId.insurance?.date}
                        disabled={formDisabled}
                        onChange={(event) => setInsuranceDate(event.target.value)}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col>
                <div>
                  <Row>
                    <Form.Label column lg={4} className="form-label mb-3">
                      premium type:
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        defaultValue={detailsWithId.insurance?.type}
                        disabled={formDisabled}
                        onChange={(event) => setInsuranceType(event.target.value)}
                      />
                    </Col>
                  </Row>
                </div>

                <div>
                  <Row>
                    <Form.Label column lg={4} className="form-label mb-3">
                      amount:
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        defaultValue={detailsWithId.insurance?.amount}
                        disabled={formDisabled}
                        onChange={(event) => setInsuranceAmount(event.target.value)}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <hr className="my-0 mb-4 mt-4" />
            <Row>
              <h4>Rc Details</h4>
              <Col>
                <div>
                  <Row>
                    <Form.Label column lg={4} className="form-label mb-3">
                      owner no:
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        defaultValue={detailsWithId.rcDetails?.owner_no}
                        disabled={formDisabled}
                        onChange={(event) => setRcOwnerNo(event.target.value)}
                      />
                    </Col>
                  </Row>
                </div>
                <div>
                  <Row>
                    <Form.Label column lg={4} className="form-label mb-3">
                      date of reg:
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="date"
                        defaultValue={detailsWithId.rcDetails?.dateOf_reg}
                        disabled={formDisabled}
                        onChange={(event) => setRcDateReg(event.target.value)}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col>
                <div>
                  <Row>
                    <Form.Label column lg={4} className="form-label mb-3">
                      reg no:
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        defaultValue={detailsWithId.rcDetails?.reg_no}
                        disabled={formDisabled}
                        onChange={(event) => setRcRegNo(event.target.value)}
                      />
                    </Col>
                  </Row>
                </div>
                <div>
                  <Row>
                    <Form.Label column lg={4} className="form-label mb-3">
                      reg validity:
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        defaultValue={detailsWithId.rcDetails?.reg_validity}
                        disabled={formDisabled}
                        onChange={(event) => setRcRegValidity(event.target.value)}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <hr className="my-0 mb-4 mt-4" />
            <h4>Expense on Purchase</h4>
            {expenseOnPurchase?.map((x, i) => (
              <Row key={i}>
                <Col>
                  <div>
                    <Row>
                      <Form.Label column lg={4} className="form-label mb-3">
                        Purpose:
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          name="purpose"
                          value={x.purpose} // Use value instead of defaultValue
                          disabled={formDisabled}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col>
                  <div>
                    <Row>
                      <Form.Label column lg={4} className="form-label mb-3">
                        Amount:
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          name="amount"
                          value={x.amount} // Use value instead of defaultValue
                          disabled={formDisabled}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
                <div className="col-md-1">
                  {expenseOnPurchase.length > 0 && (
                    <div className="">
                      <i
                        className="fa-solid fa-xmark"
                        onClick={() => handleRemove(i)}
                      ></i>
                    </div>
                  )}
                  {expenseOnPurchase.length - 1 === i && (
                    <div className="mt-4">
                      <i
                        className="fa-solid fa-plus"
                        onClick={handleAddClick}
                      ></i>
                    </div>
                  )}
                </div>
              </Row>
            ))}



            <hr className="my-0 mb-4 mt-4" sty />
            {
              detailsWithId?.sellDetails?.status ? <Row>
                <h4>Sale Details</h4>
                <Col>
                  <div>
                    <Row>
                      <Form.Label column lg={4} className="form-label mb-3">
                        customer name:
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          defaultValue={detailsWithId.sellDetails?.customerName}
                          disabled={formDisabled}
                          onChange={(event) =>
                            setCustomerName(event.target.value)
                          }
                        />
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Form.Label column lg={4} className="form-label mb-3">
                        Date of sale:
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="date"
                          defaultValue={detailsWithId.sellDetails?.dateOfSale}
                          disabled
                          onChange={(event) => setDateOfSale(event.target.value)}
                        />
                      </Col>
                    </Row>
                  </div>

                  <div>
                    <Row>
                      <Form.Label column lg={4} className="form-label mb-3">
                        Customer purchased amount:
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          defaultValue={
                            detailsWithId.sellDetails?.CustomerPurchasedAmt
                          }
                          disabled={formDisabled}
                          onChange={(event) =>
                            setCustomerPurchaseAmt(event.target.value)
                          }
                        />
                      </Col>
                    </Row>
                    {/* <div>
                  <label className="form-label fs-6 mt-2">
                    Payment Methode -
                  </label>
                </div> */}
                  </div>
                  <div>
                    <Row>
                      <Form.Label column lg={4} className="form-label mb-3">
                        Payment type:
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          defaultValue={
                            detailsWithId.sellDetails?.paymentMethode?.type
                          }
                          disabled={formDisabled}
                          onChange={(event) => setPaymentType(event.target.value)}
                        />
                      </Col>
                    </Row>
                  </div>

                  <div>
                    <Row>
                      <Form.Label column lg={4} className="form-label mb-3">
                        finance name:
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          defaultValue={
                            detailsWithId.sellDetails?.paymentMethode
                              ?.finance_name
                          }
                          disabled={formDisabled}
                          onChange={(event) => setFinance_name(event.target.value)}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Form.Label column lg={4} className="form-label mb-3">
                        loan amount:
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          defaultValue={
                            detailsWithId.sellDetails?.paymentMethode?.loan_amount
                          }
                          disabled={formDisabled}
                          onChange={(event) => setLoan_amount(event.target.value)}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Form.Label column lg={4} className="form-label mb-3">
                        disbursement amount:
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          defaultValue={
                            detailsWithId.sellDetails?.paymentMethode
                              ?.disbursement_amount
                          }
                          disabled={formDisabled}
                          onChange={(event) => setDisbursement_amount(event.target.value)}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col>
                  <div>
                    <Row>
                      <Form.Label column lg={4} className="form-label mb-3">
                        customer Phone Number:
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          defaultValue={
                            detailsWithId.sellDetails?.customerPhone
                          }
                          disabled={formDisabled}
                          onChange={(event) =>
                            setCustomerPhone(event.target.value)
                          }
                        />
                      </Col>
                    </Row>
                    {/* <label className="ms-2 fs-5"></label> */}
                  </div>
                  <div>
                    <Row>
                      <Form.Label column lg={4} className="form-label mb-3">
                        Booking amount:
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          defaultValue={detailsWithId.bookingDetails?.bookingAmt}
                          disabled={formDisabled}
                          onChange={(event) => setBookingAmt(event.target.value)}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Form.Label column lg={4} className="form-label mb-3">
                        date of Booking:
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="date"
                          defaultValue={detailsWithId.bookingDetails?.dateOfBooking}
                          disabled={formDisabled}
                          onChange={(event) =>
                            setDateOfBooking(event.target.value)
                          }
                        />
                      </Col>
                    </Row>
                  </div>

                  <div>
                    <label className="form-label mb-3"></label>
                    <label className="ms-2 fs-5"></label>
                  </div>
                  <div>
                    <Row>
                      <Form.Label column lg={4} className="form-label mb-3">
                        exicutive name:
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          defaultValue={
                            detailsWithId.sellDetails?.paymentMethode
                              ?.exicutive_name
                          }
                          disabled={formDisabled}
                          onChange={(event) => setExicutive_name(event.target.value)}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Form.Label column lg={4} className="form-label mb-3">
                        Document Ref No:
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          defaultValue={
                            detailsWithId.sellDetails?.paymentMethode?.ref_no
                          }
                          disabled={formDisabled}
                          onChange={(event) => setRef_no(event.target.value)}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Form.Label column lg={4} className="form-label mb-3">
                        Commission Amount:
                      </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          defaultValue={
                            detailsWithId.sellDetails?.paymentMethode
                              ?.commission_amount
                          }
                          disabled={formDisabled}
                          onChange={(event) => setCommission_amount(event.target.value)}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row> : ''
            }

            <div className="mt-2 pb-5 p-2">
              <span>
                <Button variant="warning" onClick={cancelBooking}>
                  Cancel Booking
                </Button>
              </span>&nbsp;
              <span>
                <Button variant="warning" onClick={multipleCancel}>
                  Cancel Sale
                </Button>
              </span>

              &nbsp;
              <span>
                <Button variant="primary" onClick={editClick}>
                  Edit
                </Button>
              </span>
              &nbsp;
              <span>
                <Button variant="danger" onClick={deleteDetail}>Delete</Button>
              </span>
              &nbsp;
              <span>
                <Button variant="success" onClick={handleSubmit}>
                  Submit
                </Button>
              </span>
              <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
              />
            </div>
          </Form>
        </div>
      </div>
    </Layout>
  );
}

export default VehicleDetails;
