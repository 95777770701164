import { useState, React } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { getAuth, signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import Spinner from 'react-bootstrap/Spinner';


function LoginPage() {
    const [phoneNumber, setphoneNumber] = useState("");
    const [expandForm, setExpandForm] = useState(false);
    const [enterOtp, setEnterOtp] = useState(false);
    const [otp, setOtp] = useState('')
    const navigate = useNavigate();
    const auth = getAuth();

    const generateRecaptcha = () => {
        window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
            'callback': (response) => {
            }
        }, auth);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let verifyNmbrOne = '7012558020'
        let verifyNumbrTwo = '9496363532'
        let verifyNumbrThree = '9645846454'

        if (phoneNumber.length >= 10 && (verifyNmbrOne == phoneNumber || verifyNumbrTwo == phoneNumber || verifyNumbrThree == phoneNumber)) {
            setExpandForm(true);
            generateRecaptcha();
            const countryCode = '+91'
            let mobileNumber = countryCode + phoneNumber;
            let appVerifier = window.recaptchaVerifier;
            signInWithPhoneNumber(auth, mobileNumber, appVerifier)
                .then((confirmationResult) => {
                    // SMS sent. Prompt user to type the code from the message, then sign the
                    // user in with confirmationResult.confirm(code).
                    window.confirmationResult = confirmationResult;
                    // ...
                }).catch((error) => {
                    // Error; SMS not sent
                    // ...
                    alert(error)
                });
        } else {
            alert("Phone Number Not Authorised")
        }
    }

    const verifyOtp = (e) => {
        let otp = e.target.value;
        setOtp(otp);
        if (otp.length === 6) {
            setEnterOtp(true);
            let confirmationResult = window.confirmationResult;
            confirmationResult.confirm(otp).then((result) => {
                // User signed in successfully.
                const user = result.user;
                // console.log('s',user);
                navigate('/home');
                // ...
            }).catch((error) => {
                // User couldn't sign in (bad verification code?)
                // ...
            });
        }
    }

    return (
        <>
            {/* OTP LOGIN    */}
            <section className="vh-100" style={{ backgroundColor: '#f5f5f9' }}>
                <div className="container py-5 h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col col-xl-10">
                            <div className="card" style={{ borderRadius: '1rem' }}>
                                <div className="row g-0">
                                    <div className="col-md-6 col-lg-5 d-none d-md-block">
                                        <img src="https://images.unsplash.com/photo-1620847969348-60db0790bc09?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDJ8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=500&q=60" alt="login form" className="img-fluid" style={{ borderRadius: '1rem 0 0 1rem' }} />
                                    </div>
                                    <div className="col-md-6 col-lg-7 d-flex align-items-center">
                                        <div className="card-body p-4 p-lg-5 text-black">
                                            <form onSubmit={handleSubmit}>
                                                <div className="d-flex align-items-center mb-3 pb-1">
                                                    {/* <i className="fa fa-cubes fa-2x me-3" style={{ color: '#ff6219' }} /> */}
                                                    <img src="logo512.png" alt="logo" className='mt-3' style={{ width: '69px', height: '69px' }} />
                                                    <span className="h1 fw-bold mb-0 " style={{ color: '#f58633' }}>True </span>&nbsp; <span className="h1 fw-bold mb-0 " style={{ color: '#00afef' }}> Choice</span>
                                                </div>
                                                <h5 className="fw-normal mb-3 pb-3" style={{ letterSpacing: 1 }}>Sign into your account</h5>
                                                <label className="form-label">Mobile Number</label>
                                                <div className="form-outline mb-4 input-group input-group-merge">
                                                    <span className="input-group-text">IND (+91)</span>
                                                    <input type="tel" className="form-control form-control-lg" placeholder='Phone number' value={phoneNumber} onChange={(e) => { setphoneNumber(e.target.value) }} />
                                                </div>
                                                {expandForm ? (
                                                    <div className="form-outline mb-4">
                                                        <input
                                                            type="number"
                                                            id="otpInput"
                                                            className="form-control form-control-lg"
                                                            placeholder="OTP Password"
                                                            value={otp}
                                                            onChange={verifyOtp}
                                                        />
                                                        <label className="form-label" htmlFor="form2Example27">
                                                            Enter OTP
                                                        </label>
                                                        {enterOtp && <Spinner animation="border" variant="info" size="sm" className='ms-1'/>}
                                                    </div>
                                                ) : (
                                                    <div className="pt-1 mb-4">
                                                        <button className="btn btn-dark btn-lg btn-block w-100 form-label" id="sign-in-button" type="submit">
                                                            Request OTP
                                                        </button>
                                                    </div>
                                                )}
                                                <div id='recaptcha-container'></div>
                                                {/* <label className="small text-muted" style={{ textDecoration: 'none' }}>Forgot password?</label>
                                                <p className="mb-5 pb-lg-2 disabled" style={{ color: '#393f81' }} >Don't have an account?<Link to={'/n'} style={{ textDecoration: 'none' }}> <a style={{ color: '#393f81' }} className="disabled" disabled>Register here</a></Link ></p> */}
                                                <label className="small text-muted" style={{ textDecoration: 'none' }}>Terms of use.</label>
                                                <label className="small text-muted" style={{ textDecoration: 'none' }}>Privacy policy</label>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LoginPage
