import { React, useState, useEffect } from 'react'
import Layout from '../Layout'
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { VscGoToFile } from "react-icons/vsc";
import { db } from "../../firebase/Config.js";
import { collection, getDocs } from "firebase/firestore";
import { FcPrint } from "react-icons/fc";
import moment from 'moment'
import jsPDF from 'jspdf'
import { Link } from "react-router-dom";
import CurrencyFormat from 'react-currency-format';
import 'jspdf-autotable';



function Purchased() {



  let siNo = 1;
  const [switchClick, setSwitchClick] = useState(true)
  const [startDate, setStartDate] = useState(moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment(new Date()).utc().format('YYYY-MM-DD'));
  const [startDateSold, setStartDateSold] = useState(moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'));
  const [endDateSold, setEndDateSold] = useState(moment(new Date()).utc().format('YYYY-MM-DD'));
  // const tomorrowDate = moment().add(1, 'days').utc().format('YYYY-MM-DD');
  const [details, setDetails] = useState('')
  const [financeDetails, setFinanceDetails] = useState([])
  const [soldData, setSoldData] = useState('');
  const [financeData, setFinanceData] = useState('')
  // console.log(soldData);
  const Click = (val) => {
    setSwitchClick(val)
  }
  function TotalExpenseOnPurchase(arr, pAmt) {
    if (!arr) {
      return "nil";
    }
    let total = 0;
    arr.forEach((item) => {
      total += Number(item.amount);
      total += Number(pAmt);
    });
    return <CurrencyFormat value={total} displayType={'text'} thousandSeparator={true} prefix={'₹'} />;
  }

  const finance = 'finance'

  useEffect(() => {
    getDocs(collection(db, "purchaseDetails")).then((result) => {
      setDetails(result.docs);
      let arr = []
      for (let item of result.docs) {
        arr.push(item.data())
      }
      let sortSoldData = arr.filter((item) => {
        return item.sellDetails && item.sellDetails.dateOfSale
      })
      let sortFinanceData = arr.filter((item) => {
        return item?.sellDetails?.paymentMethode?.type === 'finance'
      })
      setSoldData(sortSoldData);
      setFinanceData(sortFinanceData);
      setFinanceDetails([...arr])
      setDetails([...arr])
    });
  }, [switchClick]);

  useEffect(() => {
    if (startDate && endDate) {
      let sortedData = financeDetails.filter((item) => {
        return new Date(item.sellDetails.dateOfSale) > new Date(startDate) && new Date(item.sellDetails.dateOfSale) < new Date(endDate)
      });
      setFinanceData(sortedData);
    };
    if (startDateSold && endDateSold) {
      let sortedData = financeDetails.filter((item) => {
        return new Date(item.sellDetails.dateOfSale) > new Date(startDateSold) && new Date(item.sellDetails.dateOfSale) < new Date(endDateSold)
      });
      setSoldData(sortedData);
    };
  }, [startDate, endDate, endDateSold, startDateSold])


  const columnSold = [
    { title: 'No.', field: 'no' },
    { title: 'Customer Name', field: 'name' },
    { title: 'Customer Contact', field: 'contact' },
    { title: 'Vehicle Name', field: 'VehicleName' },
    { title: 'Vehicle Reg NO', field: 'regNo' },
    { title: 'Date of Vehicle Purchase', field: 'dateOfVehiclePurchase' },
    { title: 'Purchased Amount', field: 'purchasedAmount' },
    { title: 'Total Cost Of Purchase ', field: 'totalCostOfPurchase' },
    { title: 'Sale Date ', field: 'saleDate' },
    { title: 'sale Amount ', field: 'saleAmount' },
  ]


  // const soldPdf = () => {
  //   const doc = new jsPDF()
  //   doc.text("Sold Vehicles", 20, 10)
  //   doc.autoTable({
  //     columns: columnSold.map(col => ({ ...col, datakey: col.field })),
  //     body: soldData.map((item, index) => [
  //       index + 1,
  //       item?.sellDetails?.customerName || '-',
  //       item?.sellDetails?.customerPhone || '-',
  //       item?.vehicleName || '-',
  //       item?.rcDetails?.reg_no || '-',
  //       item?.dateOfPurchase || '-',
  //       item?.purchasedAmt || '-',
  //       TotalExpenseOnPurchase(
  //         item?.expenseOnPurchase, item?.purchasedAmt
  //       ) || '-',
  //       item?.sellDetails?.dateOfSale || '-',
  //       item?.sellDetails?.CustomerPurchasedAmt || '-',
  //     ]),
  //   })
  //   doc.save('Sold vehicles table.pdf');
  // }
  const soldPdf = () => {
    const doc = new jsPDF();
    doc.text("Sold Vehicles", 20, 10);
    doc.autoTable({
      columns: columnSold.map(col => ({ ...col, datakey: col.field })),
      body: soldData.map((item, index) => [
        index + 1,
        item?.sellDetails?.customerName || '-',
        item?.sellDetails?.customerPhone || '-',
        item?.vehicleName || '-',
        item?.rcDetails?.reg_no || '-',
        item?.dateOfPurchase || '-',
        item?.purchasedAmt || '-',
        item?.expenseOnPurchase ? item.expenseOnPurchase.reduce((total, expense) => total + Number(expense.amount), Number(item.purchasedAmt)) : Number(item.purchasedAmt),
        item?.sellDetails?.dateOfSale || '-',
        item?.sellDetails?.CustomerPurchasedAmt || '-',
      ]),
    });
    doc.save('Sold vehicles table.pdf');
  };
  
  const columns = [
    { title: 'No.', field: 'no' },
    { title: 'Customer Name', field: 'name' },
    { title: 'Customer Contact', field: 'contact' },
    { title: 'Vehicle Reg NO', field: 'rgNo' },
    { title: 'Vehicle date of sale', field: 'dateOfSale' },
    { title: 'Loan Amount', field: 'loanAmount' },
    { title: 'Disbursement Amount', field: 'disbursementAmount' },
    { title: 'Document Ref No', field: 'documentRefNo' },
  ]


  const financePdf = () => {
    const doc = new jsPDF()
    doc.text("Finance Details", 20, 10)
    doc.autoTable({
      columns: columns.map(col => ({ ...col, datakey: col.field })),
      body: financeData.map((item, index) => [
        index + 1,
        item?.sellDetails?.customerName || '-',
        item?.sellDetails?.customerPhone || '-',
        item?.rcDetails?.reg_no || '-',
        item?.sellDetails?.dateOfSale || '-',
        item?.sellDetails?.paymentMethode?.loan_amount || '-',
        item?.sellDetails?.paymentMethode?.disbursement_amount || '-',
        item?.sellDetails?.paymentMethode?.ref_no || '-',
      ]),
    })
    doc.save('finance vehicles table.pdf');
  }

  const [currentPage, setCurrentPage] = useState(1)
  const recordsPerPage = 25;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  let records = switchClick ? financeData.slice(firstIndex, lastIndex) : soldData.slice(firstIndex, lastIndex);
  let npage = switchClick ? Math.ceil(financeData.length / recordsPerPage) : Math.ceil(soldData.length / recordsPerPage);

  const numbers = [...Array(npage + 1).keys()].slice(1)
  const prePage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1)
    }
  }
  const changeCPage = (id) => {
    setCurrentPage(id)
  }
  const nextPage = () => {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1)
    }
  }
  return (
    <Layout activePage={'purchased'}>
      <div className='content'>
        <div className="card mt-5 rounded-4  p-3">
          <div className='d-flex'>
            <h5 className="card-header me-auto">
              {
                switchClick ?
                  'Finance vehicles'
                  :
                  'Sold vehicles'
              }
            </h5>
            <div className='d-flex justify-content-center'>
              {switchClick ?
                <>
                  <label className='p-0'>start Date :<input className='form-control' type="date" placeholder='start' value={startDate} onChange={(e) => setStartDate(e.target.value)} /></label>&nbsp;
                  <label className='p-0'>end Date :<input className='form-control' type="date" placeholder='end' value={endDate} onChange={(e) => setEndDate(e.target.value)} /></label>
                  <div> <Button onClick={financePdf} variant="light" className='fs-2'><FcPrint /></Button></div>
                </>
                :
                <>
                  <label className='p-0'>start Date :<input className='form-control' type="date" placeholder='start' value={startDateSold} onChange={(e) => setStartDateSold(e.target.value)} /></label>&nbsp;
                  <label className='p-0'>end Date :<input className='form-control' type="date" placeholder='end' value={endDateSold} onChange={(e) => setEndDateSold(e.target.value)} /></label>
                  <div> <Button onClick={soldPdf} variant="light" className='fs-2'><FcPrint /></Button></div>
                </>
              }
            </div>
            <div className='justify-content-end'>
              {switchClick ?
                <Button
                  className="ms-5 m-3"
                  onClick={() => Click(false)}
                >
                  <VscGoToFile className='fs-4' /> &nbsp;
                  Goto Sold Vehicles
                </Button>
                :
                <Button
                  className="ms-5 m-3"
                  onClick={() => Click(true)}
                >

                  <VscGoToFile className='fs-4' /> &nbsp;
                  Goto finance
                </Button>
              }
            </div>
          </div>
          <div className='table-responsive'>
            <Table hover>
              <thead>
                {switchClick ?
                  <tr key="">
                    <th>Si No</th>
                    <th>Customer Name</th>
                    <th>Customer contact</th>
                    <th>Reg No</th>
                    <th>sale date</th>
                    <th>Finance name</th>
                    <th>LOAN AMOUNT</th>
                    <th>DISBURSEMENT AMOUNT</th>
                    <th>DOCUMENT REF NO</th>
                  </tr>
                  :
                  <tr key="">
                    <th>Si No</th>
                    <th>Vehicle Name</th>
                    <th>Reg NO</th>
                    <th>Date of purchase </th>
                    <th>purchased amount</th>
                    <th>total cost of purchase </th>
                    <th>sale date</th>
                    <th>sale Amount</th>
                  </tr>
                }
              </thead>
              <tbody>
                {details && details[0]
                  ? records.map((item, index) => (
                    switchClick == true ?
                      item?.sellDetails?.paymentMethode?.type == "finance" &&
                      <tr key={index}>
                        <td>{(currentPage - 1) * recordsPerPage + siNo++}</td>
                        <td>{item?.sellDetails?.customerName}</td>
                        <td>{item?.sellDetails?.customerPhone}</td>
                        <td>{item?.rcDetails?.reg_no}</td>
                        <td>{item?.sellDetails?.dateOfSale}</td>
                        <td>{item?.sellDetails?.paymentMethode?.finance_name}</td>
                        <td>
                          <CurrencyFormat value={item?.sellDetails?.paymentMethode?.disbursement_amount} displayType={'text'} thousandSeparator={true} prefix={'₹'} />
                        </td>
                        <td>
                          <CurrencyFormat value={item?.sellDetails?.paymentMethode?.loan_amount} displayType={'text'} thousandSeparator={true} prefix={'₹'} />
                        </td>
                        <td>{item?.sellDetails?.paymentMethode?.ref_no}</td>
                      </tr>
                      :
                      switchClick == false ?
                        item?.sellDetails?.status == true &&
                        <tr key={index}>
                          <td>{(currentPage - 1) * recordsPerPage + siNo++}</td>
                          <td>{item?.vehicleName}</td>
                          <td>{item?.rcDetails?.reg_no}</td>
                          <td>{item?.dateOfPurchase}</td>
                          <td>
                            <CurrencyFormat value={item?.purchasedAmt} displayType={'text'} thousandSeparator={true} prefix={'₹'} />
                          </td>
                          <td>{
                            TotalExpenseOnPurchase(
                              item?.expenseOnPurchase, item?.purchasedAmt
                            )}
                          </td>
                          <td>{item?.sellDetails?.dateOfSale}</td>
                          <td>
                            <CurrencyFormat value={item?.sellDetails?.CustomerPurchasedAmt} displayType={'text'} thousandSeparator={true} prefix={'₹'} />
                          </td>
                        </tr> : ''
                  ))
                  : 'no data to display'}
              </tbody>
            </Table>

          </div>          
          <nav className='mt-3'>
            <ul className="pagination">
              <li className="page-item">
                <Link className="page-link me-1" onClick={prePage}>
                  Prev
                </Link>
              </li>
              {
                numbers.map((n, i) => (
                  <li
                    className={`page-item${currentPage === n ? "active" : ""}`}
                    key={i}
                  >
                    <Link className="page-link" onClick={() => changeCPage(n)}>
                      {n}
                    </Link>
                  </li>
                ))}
              <li className="page-item">
                <Link className="page-link" onClick={nextPage} >
                  Next
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </Layout>
  )
}

export default Purchased