import React, { useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase/Config";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function BookingModel({ id }) {
  const [dateOfBooking, setDateOfBooking] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [bookingAmt, setBookingAmt] = useState("");

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      await updateDoc(doc(db, "purchaseDetails", id), {
        bookingStatus: true,
        bookingDetails: {
          dateOfBooking: dateOfBooking,
          customerName: customerName,
          customerPhone: customerPhone,
          bookingAmt: bookingAmt,
        },
      });
    } catch (error) {
      alert(error);
    }
  }
  // console.log(id);
  const notify = () => toast.success('Successfully Updated!', {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    });

  return (
    <>
      <hr className="my-0" />
      <div className="card-body">
        <form  onSubmit={handleSubmit}>
          <div className="row">
            <div className="mb-3 col-md-6">
              <label className="form-label">date of Booking</label>
              <input
                className="form-control"
                type="date"
                name="dateOfBooking"
                id="lastName"
                onChange={(e) => setDateOfBooking(e.target.value)}
              />
            </div>
            <div className="mb-3 col-md-6">
              <label className="form-label">customer name</label>
              <input
                className="form-control"
                type="text"
                id="customerName"
                name="customerName"
                onChange={(e) => setCustomerName(e.target.value)}
              />
            </div>
            <div className="mb-3 col-md-6">
              <label className="form-label" for="phoneNumber">
                customer Phone Number
              </label>
              <div className="input-group input-group-merge">
                <span className="input-group-text">IND (+91)</span>
                <input
                  type="text"
                  name="customerPhone"
                  className="form-control"
                  placeholder="202 555 0111"
                  onChange={(e) => setCustomerPhone(e.target.value)}
                />
              </div>
            </div>

            <div className="mb-3 col-md-6">
              <label className="form-label">booking amount</label>
              <input
                type="text"
                className="form-control"
                name="bookingAmt"
                placeholder="amount"
                onChange={(e) => setBookingAmt(e.target.value)}
              />
            </div>
          </div>
          <div className="mt-2">
            <button
              type="submit"
              className="btn btn-primary me-2"
              onClick={notify}
            >
              Save changes
            </button>
            <button type="reset" className="btn btn-outline-secondary">
              Cancel
            </button>
            <ToastContainer
              position="bottom-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="dark"
            />
          </div>
        </form>
      </div>
    </>
  );
}

export default BookingModel;
