import {React,useState} from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../../firebase/Config";

function AddExpense() {

    const [name, setName] = useState('')
    const [date, setDate] = useState('')
    const [purpose, setPurpose] = useState('')
    const [amount, setAmount] = useState('')

    const notify = () => toast.success('Successfully Updated!', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let id = Date.now().toString();
            await setDoc(doc(db, "expenseTrack", id), {
                id,
                name,
                date,
                purpose,
                amount
            });
            e.target.reset();
        } catch (error) {
            alert(error);
        }
    } 


    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="mb-3 col-md-6">
                        <label className="form-label">name</label>
                        <input
                            className="form-control"
                            type="text"
                            name="name"
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <div className="mb-3 col-md-6">
                        <label className="form-label">date</label>
                        <input
                            className="form-control"
                            type="date"
                            name="date"
                            onChange={(e) => setDate(e.target.value)}
                        />
                    </div>
                    <div className="mb-3 col-md-6">
                        <label className="form-label">
                            purpose
                        </label>
                        <input
                            className="form-control"
                            type="text"
                            name="purpose"
                            onChange={(e) => setPurpose(e.target.value)}
                        />
                    </div>

                    <div className="mb-3 col-md-6">
                        <label className="form-label">amount</label>
                        <input
                            type="text"
                            className="form-control"
                            name="amount"
                            placeholder="amount"
                            onChange={(e) => setAmount(e.target.value)}
                        />
                    </div>
                </div>
                <div className="mt-2">
                    <button
                        type="submit"
                        className="btn btn-primary me-2"
                        onClick={notify}
                    >
                        Save changes
                    </button>
                    <button type="reset" className="btn btn-outline-secondary">
                        Cancel
                    </button>
                    <ToastContainer
                        position="bottom-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="dark"
                    />
                </div>
            </form>

        </>
    )
}

export default AddExpense