import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import SellButtonModel from "./modal/SellButtonModel.js";
import AddButtonModal from "./modal/AddButtonModal.js";
import { Col, Row } from "react-bootstrap";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase/Config.js";
import { FcBookmark, FcApproval } from "react-icons/fc";
import BookingModel from "./modal/BookingModel.js";
import { BsThreeDotsVertical } from "react-icons/bs";
import Table from 'react-bootstrap/Table';
import CurrencyFormat from 'react-currency-format';

function StockCard() {
  const [vehicleDetails, setVehicleDetails] = useState("");
  const [sellmodal, SetSellModal] = useState(false);
  const [addModal, SetAddModal] = useState(false);
  const [bookingModel, setBookingModel] = useState(false);
  const [vehicleid, setVehicleid] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [totalExpenseOnPurchase, setTotalExpenseOnPurchase] = useState("");
  const [filteredDetails, setFilteredDetails] = useState([]); // Filtered data
  const [inputValue, setInputValue] = useState("");
  const [addNotification, setAddNotification] = useState(false);
  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 25;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = filteredDetails.slice(firstIndex, lastIndex);
  const nPage = Math.ceil(filteredDetails.length / recordsPerPage);
  const numbers = [...Array(nPage + 1).keys()].slice(1);

  function nextPage() {
    if (currentPage !== nPage) {
      setCurrentPage(currentPage + 1);
    }
  }
  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function TotalExpenseOnPurchase(arr, pAmt) {

    if (!arr) {
      return "nil";
    }
    let total = 0;
    arr.forEach((item) => {
      total += Number(item.amount);
      // total += Number(pAmt);
    });
    // console.log('t',total+= );

    return total+=Number(pAmt);
  }

  const searchDetail = (event) => {
    const inputValue = event.target.value;
    setInputValue(inputValue);
    if (inputValue !== "") {
      const filteredData = vehicleDetails.filter(
        (val) =>
          val
            ?.data()
            ?.rcDetails?.reg_no?.toLowerCase()
            ?.includes(inputValue?.toLowerCase()) ||
          val
            ?.data()
            ?.vehicleName?.toLowerCase()
            ?.includes(inputValue?.toLowerCase())
      );
      setFilteredDetails(filteredData);
    } else {
      setFilteredDetails(vehicleDetails);
    }
  };

  const addModalNoti = (notification) => {
    setAddNotification(notification);
  };



  useEffect(() => {
    getDocs(collection(db, "purchaseDetails")).then((result) => {
      setVehicleDetails(result.docs);
      setFilteredDetails(result.docs);
      // console.log('stock');
      // console.log(TotalExpenseOnPurchase(result.docs[2].data().expenseOnPurchase))
    });
    
  }, [addNotification]);



  return (
    <div>
      <div className="content mb-5">
        <div className="input-group rounded mt-5 shadow" >
          <span className="input-group-text border-0" id="search-addon">
            <i className="fas fa-search" />
          </span>
          <input
            type="search"
            className="form-control rounded"
            placeholder="Search....vehicle name,reg no here !"
            aria-label="Search"
            aria-describedby="search-addon"
            style={{ height: "3.5rem" }}
            value={inputValue}
            onChange={searchDetail}

          />
        </div>
        <div className="">
          <div className=" bg-light rounded-4 table-box shadow mt-4 p-3 "
          //  style={{overflowX:'scroll'}}
          >
            <div className="d-flex">
            <h5 className="card-header me-auto">Stock List</h5>
            <div className="">
              
            <Button
                  variant="warning"
                  className="ms-5 m-3"
                  onClick={() => SetAddModal(true)}
                >
                  <i className="fa-solid fa-plus "></i> Add
                </Button>
            </div>
            {/* <div><input type="text" className="form-control mt-3"/></div> */}
            </div>
            {/* <Row>
              <Col sm={9}>
                <h5 className="card-header">Stock List</h5>
              </Col>
              <Col sm={3}>
                <Button
                  variant="warning"
                  className="ms-5 m-3"
                  onClick={() => SetAddModal(true)}
                >
                  <i className="fa-solid fa-plus "></i> Add
                </Button>
              
              </Col>
            </Row> */}
            <Table hover>
              <thead>
                <tr className='text-center'>
                  <th>Sl No</th>
                  <th>Date Of Purchase</th>
                  <th>Vehicle Name</th>
                  <th>Reg No</th>
                  <th>Purchased Amount</th>
                  <th>Total Cost of Purchase</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody className="table-border-bottom-0">
                {vehicleDetails && vehicleDetails.length > 0
                  ? records
                    .map((item, index) => (
                      <tr key={index}>
                        <td className="text-center">
                          {(currentPage - 1) * recordsPerPage + index + 1}
                        </td>
                        <td className="text-center">{item?.data()?.dateOfPurchase}</td>
                        <td className="text-center">{item?.data()?.vehicleName}</td>
                        <td className="text-center">{item?.data()?.rcDetails?.reg_no}</td>
                        <td className="text-center">
                          <CurrencyFormat value={item?.data()?.purchasedAmt} displayType={'text'} thousandSeparator={true} prefix={'₹'} />
                        </td>
                        <td className="text-center">
                          <CurrencyFormat
                            value={
                              TotalExpenseOnPurchase(
                                item?.data()?.expenseOnPurchase, item?.data()?.purchasedAmt
                              )
                            }
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'₹'} />
                        </td>
                        <td className="text-center">
                          {item?.data()?.bookingStatus ? (
                            <FcBookmark className="text-success fs-4 ms-3 mb-1" />
                          ) : (
                            ""
                          )}
                          {item?.data()?.sellDetails?.status ? (
                            <FcApproval className="text-success fs-4 ms-3 mb-1" />
                          ) : (
                            ""
                          )}
                          {!item?.data()?.bookingStatus &
                            !item?.data()?.sellDetails?.status
                            ? "Pending"
                            : ""}
                        </td>
                        <td>
                          <div className="d-flex flex-row fs-4">
                            <div>
                              <Link to={`/vehicledetails/${item?.data()?.id}`}>
                                <i className="fa-solid fa-pen-to-square "></i>
                              </Link>
                            </div> &nbsp;

                            <div className="dropdown">
                              <button type="button" className="btn p-0 dropdown-toggle hide-arrow " data-bs-toggle="dropdown">
                                <BsThreeDotsVertical />
                              </button>
                              <div className="dropdown-menu">
                                <li className="dropdown-item text-center">
                                  {item?.data()?.sellDetails?.status ? (
                                    null
                                  ) : (
                                    <span className="">
                                      <Button
                                        style={{
                                          borderRadius: '20px',
                                          fontSize: '10px'
                                        }}
                                        variant="success"
                                        onClick={() => {
                                          setVehicleid(item?.data()?.id);
                                          SetSellModal(true);
                                        }}
                                      >
                                        <i className="fa-solid fa-check"></i>
                                        &nbsp;SELLED
                                      </Button>
                                    </span>
                                  )}
                                </li>
                                <li className="dropdown-item text-center">
                                  {item?.data()?.bookingStatus || item?.data()?.sellDetails?.status ? (
                                    null
                                  ) : (
                                    <span>
                                      <Button
                                        style={{
                                          borderRadius: '20px',
                                          fontSize: '10px'
                                        }}
                                        variant="primary"
                                        onClick={() => {
                                          setVehicleid(item?.data()?.id);
                                          setBookingModel(true);
                                        }}
                                      >
                                        <i className="fa-solid fa-bookmark"></i>
                                        &nbsp;BOOK
                                      </Button>
                                    </span>
                                  )}</li>
                              </div>
                            </div>
                          </div >
                        </td>
                      </tr>
                    ))
                  : "no data to display"}
              </tbody>
            </Table>
            <nav className="mt-3">
              <ul className="pagination">
                <li className="page-item">
                  <Link className="page-link me-1" onClick={prePage}>
                    Prev
                  </Link>
                </li>
                {numbers.map((n, i) => (
                  <li
                    className={`page-item${currentPage === n ? "active" : ""}`}
                    key={i}
                  >
                    <Link className="page-link" onClick={() => changeCPage(n)}>
                      {n}
                    </Link>
                  </li>
                ))}
                <li className="page-item">
                  <Link className="page-link" onClick={nextPage}>
                    Next
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        {/* modal for sell */}
        <Modal
          size="lg"
          show={sellmodal}
          onHide={() => SetSellModal(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Sell Product
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SellButtonModel id={vehicleid} />
          </Modal.Body>
        </Modal>

        {/* modal for add */}
        <Modal
          size="lg"
          show={addModal}
          onHide={() => SetAddModal(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Add Product
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddButtonModal notification={addModalNoti}/>
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          show={bookingModel}
          onHide={() => setBookingModel(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              New Booking
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <BookingModel id={vehicleid} />
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default StockCard;
