import React, { Children } from 'react'
import SideBar from './Dashboard/SideBar'

function Layout(props) {
  return (
    <>
    <SideBar activePage={props.activePage}/>
   <div className='ms-1'> {props.children }</div>
    </>
  )
}

export default Layout