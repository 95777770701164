
import { React, useState, useEffect } from 'react'
import Layout from '../Layout'
import { Button, Col, Table } from 'react-bootstrap'
import { Row } from 'react-bootstrap'
import Modal from "react-bootstrap/Modal";
import AddExpense from './modal/AddExpense';
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase/Config.js";
import { Link } from 'react-router-dom';
import moment from 'moment'
import { doc, getDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { AiFillDelete } from "react-icons/ai";
import CurrencyFormat from 'react-currency-format';

function ExpenseTrack() {
  const [addModal, SetAddModal] = useState(false);
  const [expenseDetails, setExpenseDetails] = useState('')
  const [expenseDetailSame, setExpenseDetailSame] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const recordsPerPage = 25;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = expenseDetails.slice(firstIndex, lastIndex);
  const npage = Math.ceil(expenseDetails.length / recordsPerPage)
  const numbers = [...Array(npage + 1).keys()].slice(1)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState(moment(new Date()).utc().format('YYYY-MM-DD'))
  const [deleteInvoked, setDeleteInvoked] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [dataFetchedAfterDelete, setDataFetchedAfterDelete] = useState(false);
  const prePage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1)
    }
  }
  const changeCPage = (id) => {
    setCurrentPage(id)
  }
  const nextPage = () => {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1)
    }
  }
  const handleDelete = async (id) => {
    await deleteDoc(doc(db, "expenseTrack", id));
    setDeleteInvoked(true);
    setIsDeleted(true);
  }


  useEffect(() => {
    getDocs(collection(db, "expenseTrack")).then((result) => {
      setExpenseDetails(result.docs);
      let arr = []
      for (let item of result.docs) {
        arr.push(item.data())
      }
      setExpenseDetailSame([...arr])
      setExpenseDetails([...arr])
      setDataFetchedAfterDelete(true);
    });
  }, [addModal, deleteInvoked]);

  useEffect(() => {
    if (isDeleted) {
      const timer = setTimeout(() => {
        setIsDeleted(false);
        setDeleteInvoked(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [isDeleted]);

  useEffect(() => {
    if (startDate && endDate) {
      let sortData = expenseDetailSame.filter((item) => {
        return new Date(item.date) > new Date(startDate) && new Date(item.date) < new Date(endDate)
      })
      console.log(sortData);
      setExpenseDetails(sortData);
    }
  }, [startDate, endDate])



  return (
    <Layout activePage={'expense'}>
      <div className='content'>

        <div className="card mt-5 rounded-4  p-3">
          <div className='d-flex'>
            <h5 className="card-header me-auto">Expense Track</h5>
            <div className='d-flex justify-content-end'>
              <label className='p-0'>start Date :<input className='form-control' type="date" placeholder='start' value={startDate} onChange={(e) => setStartDate(e.target.value)} /></label>&nbsp;
              <label className='p-0'>end Date :<input className='form-control' type="date" placeholder='end' value={endDate} onChange={(e) => setEndDate(e.target.value)} /></label>
            </div>
            <Button
              variant="warning"
              className="ms-5 m-3"
              onClick={() => SetAddModal(true)}
            >
              <i className="fa-solid fa-plus "></i> Add
            </Button>

          </div>



          <div className="table-responsive text-nowrap">

            <Table hover>
              <thead>
                <tr key="">
                  <th>Si No</th>
                  <th>Name</th>
                  <th>purpose</th>
                  <th>amount</th>
                  <th>date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {expenseDetails && expenseDetails.length > 0
                  ? records.map((item, index) => (
                    <tr key="index">
                      <td>{(currentPage - 1) * recordsPerPage + index + 1}</td>
                      <td>{item?.name}</td>
                      <td>{item?.purpose}</td>
                      <td>
                      <CurrencyFormat value={item?.amount} displayType={'text'} thousandSeparator={true} prefix={'₹'} />
                      
                        </td>
                      <td>{item?.date}</td>
                      <td><Button variant="light" onClick={() => handleDelete(item.id)}><AiFillDelete className='text-danger fs-4 ' /></Button></td>
                    </tr>

                  ))
                  : 'no data to display'}
              </tbody>
            </Table>
            <nav className='mt-3'>
              <ul className="pagination">
                <li className="page-item me-1">
                  <Link className="page-link" onClick={prePage}>
                    Prev
                  </Link>
                </li>
                {
                  numbers.map((n, i) => (
                    <li
                      className={`page-item${currentPage === n ? "active" : ""}`}
                      key={i}
                    >
                      <Link className="page-link" onClick={() => changeCPage(n)}>
                        {n}
                      </Link>
                    </li>
                  ))}
                <li className="page-item">
                  <Link className="page-link" onClick={nextPage} >
                    Next
                  </Link>
                </li>
              </ul>
            </nav>

          </div>
        </div>
      </div>

      <Modal
        size="lg"
        show={addModal}
        onHide={() => SetAddModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Add Product
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddExpense />
        </Modal.Body>
      </Modal>
    </Layout>
  )
}

export default ExpenseTrack