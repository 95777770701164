import React, { useEffect, useContext, useState } from 'react'
import './SideBar.css'
import { Link, useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { getAuth, signOut } from "firebase/auth";
import { FcMenu } from "react-icons/fc";
import Swal from 'sweetalert2'


function SideBar({ activePage }) {

  const navigate = useNavigate();
  const [navMenu, setNavMenu] = useState(false)

  const [isOpen,setIsOpen] = useState(false)
  // const toggle = () => setIsOpen(!isOpen)

  function logOut() {
    Swal.fire({
      title: 'Are you sure? Logout',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Logout!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        const auth = getAuth();
        signOut(auth).then(() => {
          // alert('Logout');
          navigate('/')
        }).catch((error) => {
          alert(error.message)
        });
      } 
    })
  }
  // console.log(navMenu);

  useEffect(() => {
  }, [])

  return (
    <>
       <div className='sidebar rounded-end-4 d-flex flex-column'>
        <div className='p-2 m-4 mb-1 d-flex flex-row'>       
          <label>
            <Link to={'/home'}>
              <span className="h1 fw-bold mb-0 iconName" style={{ color: '#f58633' }} >True </span>&nbsp; <span className="h1 fw-bold mb-0 iconName" style={{ color: '#00afef' }}> Choice</span><hr></hr>
            </Link>
          </label>
          {/* <label className='fs-2' ><FcMenu className='sideBarMenu'/></label> */}
          
        </div>
        <div className={navMenu ? "sidebarLink" : "sidebar_hidden"}>
          <div className='side-Link'>


            <Link to={'/home'} className={activePage == 'home' ? "active text-muted fs-5 " : 'text-muted fs-5'}><i className="sideBarIcon fa-solid fa-house me-2"></i><label className='iconName'>HOME</label></Link >
            <Link to={'/stockList'} className={activePage == 'stocklist' ? "active text-muted fs-5" : 'text-muted fs-5'}><i className="sideBarIcon fa-solid fa-list me-2"></i><label className='iconName'>STOCK LIST</label ></Link >
            <Link to={'/purchaselist'} className={activePage == 'purchased' ? "active text-muted fs-5" : 'text-muted fs-5'}><i className="sideBarIcon fa-solid fa-cart-shopping me-2"></i><label className='iconName'>SALED VEHICLES</label ></Link >
            <Link to={'/expense'} className={activePage == 'expense' ? "active text-muted fs-5" : 'text-muted fs-5'}><i className="sideBarIcon fa-solid fa-wallet me-2"></i><label className='iconName'>EXPENSE</label ></Link >
          </div>
            <Row onClick={logOut} className='side-Link ms-1'>
              <Col sm={9}>&nbsp;
              <span className="d-none d-sm-inline mx-1 " >
                {/* Logout &nbsp; */}
              <i className="fa-solid fa-right-from-bracket fs-3 " style={{ color: '#f58633' }}></i></span></Col>
              <Col sm={3}><span className='ms-4'></span></Col>
            </Row>
        </div>

      </div> 
      {/* <label className='sidebar-toggle fs-2' ><img src="assets/logo.png" alt="LOGO" /></label> */}
    </>
  )
}

export default SideBar